@import "./variable.scss";

@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1200px !important;
    }
}
@media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1440px !important;
    }
}

.form-error-message {
    color: $sc-error;
    font-size: 11.7px;
    margin-top: 3.25px;
}

.sub-bg {
    background-color: #fff;
    border-radius: 5px;
}

.h-5 {
    height: 5%;
}

.h-95 {
    height: 95%;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #3ea8b8 !important;
    border-color: #3ea8b8 !important;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #696c71 !important;
    background-color: #fff;
    border: 1px solid #e4e6ef;
}

// radio
.radio_button {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.radio_button li {
    float: left;
    margin: 0 5px 0 0;
    width: 48%;
    height: 35px;
    position: relative;
}

.radio_button label,
.radio_button input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #00000080;
    font-weight: 600;
}

.radio_button input[type="radio"] {
    opacity: 0.01;
    z-index: 100;
    width: 100%;
    height: 100%;
}

.radio_button input[type="radio"]:checked + label,
.Checked + label {
    background: #3ea8b8;
}

.radio_button label {
    text-align: center;
    padding: 5px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background: #d9d9d9;
    cursor: pointer;
    z-index: 90;
}

.radio_button label:hover {
    background: #ddd;
}

.radio_button.three-btn li {
    float: left;
    margin: 0;
    width: 32%;
    height: 35px;
    position: relative;
}
//end radio
.MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: default;
    background-color: #00000018;
}
.MuiFormHelperText-contained {
    margin-left: 0px !important;
    position: absolute;
    margin-right: 14px;
    bottom: -20px;
    text-wrap: nowrap;
}

.text-lines-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.text-lines-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.text-lines-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.text-lines-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
}
.text-lines-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

//text
.text-primary-app {
    color: $sc-primary !important;
}

// button
.t-btn-primary.MuiButtonBase-root,
.t-btn-primary {
    background-color: $sc-primary;
    border: 1px solid $sc-primary;
    color: #fff;
}
.t-btn-primary.MuiButtonBase-root:hover,
.t-btn-primary:hover {
    background-color: #0000;
    color: $sc-primary;
}
.t-btn-primary.disabled.MuiButtonBase-root:hover,
.t-btn-primary.disabled:hover {
    background-color: $sc-primary;
    border: 1px solid $sc-primary;
    color: #fff;
}
.t-btn-primary-outline.MuiButtonBase-root,
.t-btn-primary-outline {
    background-color: #0000;
    border: 1px solid $sc-primary;
    color: $sc-primary;
}
.t-btn-primary-outline.MuiButtonBase-root:hover,
.t-btn-primary-outline:hover {
    background-color: $sc-primary;
    color: #fff;
}
.t-btn-primary-outline.disabled.MuiButtonBase-root:hover,
.t-btn-primary-outline.disabled:hover {
    background-color: #0000;
    border: 1px solid $sc-primary;
    color: $sc-primary;
}
.t-btn-secondary.MuiButtonBase-root,
.t-btn-secondary {
    background-color: $sc-secondary;
    border: 1px solid $sc-secondary;
    color: #fff;
}
.t-btn-secondary.MuiButtonBase-root:hover,
.t-btn-secondary:hover {
    background-color: #0000;
    color: $sc-secondary;
}
.t-btn-secondary.disabled.MuiButtonBase-root:hover,
.t-btn-secondary.disabled:hover {
    background-color: #0000;
    color: $sc-secondary;
}

.t-btn-gray.MuiButtonBase-root,
.t-btn-gray {
    background-color: $sc-gray;
    border: 1px solid $sc-gray;
    color: #808080;
}
.t-btn-gray.MuiButtonBase-root:hover,
.t-btn-gray:hover {
    background-color: #0000;
    border: 1px solid #cbd0d5;
}
.t-btn-primary.MuiButtonBase-root,
.t-btn-primary,
.t-btn-secondary.MuiButtonBase-root,
.t-btn-secondary .t-btn-gray.MuiButtonBase-root,
.t-btn-gray,
.t-btn-primary-outline.MuiButtonBase-root,
.t-btn-primary-outline {
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
}
.t-btn-primary.MuiButtonBase-root:active,
.t-btn-primary:active,
.t-btn-secondary.MuiButtonBase-root:active,
.t-btn-secondary:active .t-btn-gray.MuiButtonBase-root:active,
.t-btn-gray:active,
.t-btn-primary-outline.MuiButtonBase-root:active,
.t-btn-primary-outline:active {
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
}

// === start table style custom
.student-profile.t-table-custom {
    thead tr th:first-child,
    tbody tr td:first-child {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 40px !important;
        text-align: center;
    }
    thead tr th:first-child {
        padding-left: 20px;
    }
}
.t-table-custom {
    .MuiTableCell-head {
        color: #a0a0a0;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5rem;
    }
    .MuiTableCell-body {
        font-size: 13px !important;
    }
    .MuiTableCell-paddingNone .MuiButtonBase-root.MuiIconButton-root {
        border-radius: 6px;
        color: #b5b5b5;
        background-color: #a0a0a01c;
        padding: 8px;
        margin: 0 3px;
    }
    .MuiTableCell-paddingNone .MuiButtonBase-root.MuiIconButton-root:hover {
        background-color: $sc-primary;
        color: #555;
    }
    .MuiTableCell-paddingNone .MuiButtonBase-root.MuiIconButton-root:active {
        transform: scale(1.025);
        -webkit-transform: scale(1.025);
    }
    th {
        color: #a0a0a0;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5rem;
        border-bottom-width: 1px !important;
        text-align: center;
    }
}
// === End table style custom

// ===- form control
.form-control-textarea {
    border-color: rgba(0, 0, 0, 0.23);
    &.in-valid {
        border-color: #dc3545;
    }
    &.form-control:focus,
    &:focus {
        border-color: $sc-primary !important;
        box-shadow: 0 0 0 1px $sc-primary !important;
    }
    &.form-control.in-valid:focus,
    &.in-valid:focus {
        border-color: #dc3545 !important;
        box-shadow: 0 0 0 1px #dc3545 !important;
    }
}
.chk-custom-formcontrol {
    input {
        display: none;
    }
    label {
        position: relative;
        padding-right: 2rem;
    }
    label::after {
        content: "";
        position: absolute;
        right: 0;
        top: -0.35em;
        width: 1.75em;
        height: 1.75em;
        border-radius: 6px;
        background-color: $sc-gray;
    }
    input:checked + label::after {
        background-color: $sc-secondary;
        background-image: url(/media/svg/icons/Navigation/Check-white.svg);
        background-position: center;
        background-repeat: no-repeat;
    }
}
// ===- end form control

.row-chk-role {
    .col-2 {
        flex: 0 0 calc(100% / 5);
        max-width: calc(100% / 5);
    }
}

.schedule-view-months-app {
    .fc-daygrid-day-top {
        flex-direction: row !important;
    }
    .fc-daygrid-event {
        padding: 5px 0px;
    }
    .fc-col-header thead .fc-col-header-cell .fc-scrollgrid-sync-inner {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #797979;
        text-align: start;
        font-weight: 100;
    }
    .fc-timegrid-col.fc-day .fc-timegrid-event .fc-event-main {
        overflow: hidden;
    }
    .fc-daygrid-event .fc-event-time {
        display: none;
    }
    .fc-daygrid-event-harness .schedule-holidays-app {
        // display: inline-block;
        text-align: center;
    }
    .fc-popover.fc-more-popover .fc-direction-ltr .fc-daygrid-event .fc-event-time {
        display: none;
    }
}
.schedule-view-weeks-app {
    .fc-daygrid-day-top {
        flex-direction: row !important;
    }
    .fc-daygrid-event {
        padding: 5px 0px;
    }
    .fc-col-header thead .fc-col-header-cell .fc-scrollgrid-sync-inner {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #797979;
        text-align: start;
        font-weight: 100;
    }
    .fc-daygrid-event .fc-event-time {
        display: none;
    }
    .fc-daygrid-event-harness {
        text-align: center;
    }
}

// list item classroom at schedule
.box-schedule-calendar-app {
    max-height: calc((200px - (10px + 0.75rem)) * 3);
    overflow-y: auto;
    padding: 5px !important;
    .card-item-calendar-app {
        min-height: 200px;
        box-shadow: 1px 2.5px 5px #5552;
    }
}

// products at create order
.card-products-app {
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}
.card-products-app.active {
    box-shadow: 0 0 0 2px $sc-primary;
    border-color: $sc-primary !important;
}
.table-items-order-app {
    td {
        vertical-align: middle !important;
    }
}

// profile students
.img-profile-student {
    max-width: 100%;
    // height: 150px;
    // border-radius: 50%;
    // object-fit: contain;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: contain;
    border: 2px solid #d0d0d030;
}

.search-course-menu-container {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 30vw;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px;
}

.search-course-menu-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
}

.search-course-menu-item:last-child {
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
}

.search-course-menu-item:hover {
    background-color: $sc-primary;
    cursor: pointer;
}

// image-products-app
.image-products-app {
    max-width: 100%;
    height: 80px;
}

.tabs-create-schedule {
    * {
        transition: all 0.15s ease-in-out;
    }
    .MuiButtonBase-root {
        background-color: $sc-gray;
        border-radius: 5px 5px 0 0;
        color: #303030;
    }
    .MuiButtonBase-root.Mui-selected {
        background-color: $sc-primary;
        border-radius: 5px 5px 0 0;
        color: #ffffff;
        font-weight: bold;
    }
}

.table-create-schedule {
    thead tr th {
        text-align: center;
    }
    * {
        transition: all 0.15s ease-in-out;
    }
    div.MuiTextField-root {
        width: 150px;
    }
    td.td-date-time.time div.MuiTextField-root {
        width: 110px;
    }
    td.selected-classroom .__value-container {
        width: 160px;
    }
    td.selected-students .__value-container {
        width: 130px;
    }
    td.selected-students.multi .__value-container {
        width: 220px;
    }
    td.selected-menu .__value-container {
        width: 190px;
    }
    td.selected-teachers .__value-container {
        width: 210px;
    }
    div.text-coursemenu-app {
        width: 180px;
        line-height: 1.4;
        max-height: 5ex;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .selected-students .select2-app.__control {
        max-width: 260px;
    }
    .MuiAutocomplete-root > .MuiFormControl-root > p {
        width: 100%;
    }
}

@media print {
    .print-custom {
        background-color: white !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
    .print-custom {
        .table thead th {
            background-color: $sc-primary !important;
        }
        .table td.total-color {
            background-color: #b6a68b !important;
        }
    }
    .print-custom {
        .pages {
            page-break-inside: avoid;
        }
    }
}

// animation fade dropdonw
@keyframes fadeDropdown {
    from {
        opacity: 0;
        transform: scale(0.975);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
