@import "./variable.scss";

.MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: none !important;
    pointer-events: none;
}

.svc-creator__banner {
    display: none;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
    background: none;
    color: #3ea8b8;
    font-weight: 600;
}

// Start Full Calendar CSS
.fc .fc-toolbar {
    flex-direction: column !important;
    align-items: flex-end !important;
    justify-content: center !important;
}

.fc .fc-toolbar > * {
    margin-bottom: 10px;
}

.fc .fc-toolbar > :last-child {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.fc-direction-ltr .fc-toolbar > * > button,
.fc-direction-ltr .fc-toolbar > * > .fc-button-group {
    margin-bottom: 5px !important;
}

@media only screen and (max-width: 500px) {
    .fc .fc-toolbar {
        align-items: flex-start !important;
    }

    .fc .fc-toolbar > :last-child {
        justify-content: start;
        flex-wrap: wrap;
    }

    .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
        margin-left: 0em !important;
    }

    .fc-direction-ltr .fc-toolbar > * > :not(:last-child) {
        margin-right: 0.75em !important;
    }

    //tui image editor
    .tui-image-editor-container .tui-image-editor-help-menu.top {
        top: 0px;
        left: -22% !important;
        transform: scale(0.7) !important;
    }
    .tui-image-editor-main-container .tui-image-editor-header {
        left: -30% !important;
        transform: scale(0.7) !important;
    }
    .svc-logic-question-value,
    .sd-element--with-frame:not(.sd-element--collapsed) {
        padding-left: 2% !important;
        padding-right: 2% !important;
    }
}
// End Full Calendar CSS

// Start React Dropzone Uploader CSS
.dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 120px;
    overflow: hidden !important;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    transition: all 0.15s linear;
    border: 1px solid #e4e6ef !important;
    border-radius: 4px;
    background-color: #fff !important;
    box-shadow: none;
}

.dzu-previewContainer {
    height: 150px;
    justify-content: space-evenly !important;
}

.dzu-previewImage {
    min-height: 130px !important;
}
// End React Dropzone Uploader CSS

// Start Toast Ui Image Editor CSS
.tui-image-editor-help-menu {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 60px !important;
}

.tui-image-editor-main {
    background-color: #fff;
}

.tui-image-editor-download-btn,
.tui-image-editor-header-logo {
    display: none !important;
}

.tui-image-editor-container .tui-image-editor-controls,
.tui-image-editor-main-container {
    background-color: #ffffff !important;
}
// End Toast Ui Image Editor CSS

// Start MaterailTable CSS
.MuiPaper-root {
    z-index: 0 !important;
}
// End MaterailTable CSS

// Start Sweetalert2 CSS
.swal-confirm-button {
    background-color: $sc-secondary !important;
    border: 1px solid $sc-secondary !important;
    color: white !important;
}

.swal-cancel-button {
    background-color: $sc-gray !important;
    border: 1px solid $sc-gray !important;
    color: $sc-dark !important;
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}
// End Sweetalert2 CSS

// start surveyjs
.sd-element__title .sv-string-viewer {
    color: #3ea8b8;
}

.sd-root-modern {
    background-color: #ffffff !important;
}

.sd-body--responsive .sd-page {
    padding: 0 !important;
}

.sd-input {
    background-color: #ffffff !important;
    box-shadow: none !important;
    border: 1px solid #f5f5f5 !important;
}

.sd-input:focus {
    border: 1px solid #3ea8b8 !important;
}

.svc-logic-question-value,
.sd-element--with-frame:not(.sd-element--collapsed) {
    border-radius: 0px !important;
    border-bottom: 1px solid #d9d9d9 !important;
    padding-top: 0px !important;
    box-shadow: none !important;
}

.sd-action-bar .sd-footer .sd-body__navigation .sd-clearfix,
.sv-action#sv-nav-complete,
.sv-action#sv-nav-complete .sv-action__content,
.sd-btn.sd-btn--action.sd-navigation__complete-btn {
    width: 100% !important;
}
// end surveyjs

.show.dropdown .bg-light-primary, .dropdown .bg-light-primary {
    background-color: transparent !important;
}