// up ==============================
@media (min-width: 576px) {
    .dialog-form-schedule.modal-dialog{
        min-width: 95vw;
        .modal-content{
            min-height: 95vh;
        }
    }
}

// down ============================
@media (max-width: 1180px) {
    .role-form-sive{
        min-width: 750px;
        overflow-y: auto;
    }
    .table-create-schedule{
        thead{
            display: none;
        }
        tbody tr td{
            display: block;
            > div{
                width: 100%;
            }
        }
        tbody tr td.align-middle:first-child{
            font-weight: 800;
            &::before{
                content: "#";
            }
        }
        div.MuiTextField-root, .selected-students .select2-app.__control, div.MuiTextField-root[format="HH:mm"]{
            max-width: 100%;
        }
    }
}
@media (max-width: 768px) {
    // image-products-app
    .image-products-app{
        max-width: 100%;
        height: 40px;
    }
}