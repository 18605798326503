@import "./variable.scss";

.FS16PX_P {
    font-size: 16px !important;
    th {
        font-size: 16px !important;
    }
    td {
        font-size: 16px !important;
    }
}

.FS18PX_P {
    font-size: 18px !important;
}

.FS16PX_P .MuiInputBase-root {
    font-size: 16px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

.select2-app.__menu-portal {
    z-index: 15;
}

.fixed-h-lg {
    min-height: 36.5px;
    .MuiInputBase-root {
        min-height: 36.6px;
    }
    .__control {
        min-height: 36.6px !important;
    }
}

.MuiOutlinedInput-inputMarginDense {
    min-height: 18.6px;
}

.MuiTableRow-root.MuiTableRow-footer {
    position: relative;
}
